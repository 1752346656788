/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js
 * - /npm/@splidejs/splide@4.1.4/dist/js/splide.min.js
 * - /npm/swiper@9.4.1/swiper-bundle.min.js
 * - /npm/jquery@3.7.0/dist/jquery.min.js
 * - /npm/aos@3.0.0-beta.6/dist/aos.min.js
 * - /npm/inputmask@5.0.8/dist/inputmask.min.js
 * - /gh/studio-freight/lenis@0.2.28/bundled/lenis.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
